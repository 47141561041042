@import './_mixin.scss';
@import './_svg.scss';

$default: #000;
$primary: #fff;
// ============================== Common 시작 ==============================
.ReactModal__Body--open {
	overflow: hidden;
	&.pc {
		#root {
			padding-right: 0.6rem;
			&::before {
				content: '';
				display: block;
				width: 0.6rem;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				background: #c5c5c5;
			}
			&::after {
				width: calc(100% - 0.6rem);
			}
			header {
				padding-right: 0.6rem;
				&::before {
					width: calc(100% - 0.6rem);
				}
			}
		}
	}
}
.ReactModal__Overlay {
	background: none !important;
	z-index: 9999;
}
.ReactModal__Content {
	background: rgba($default, 0.4);
	width: 100vw;
	height: 100vh;
	animation: modal-open 0.5s ease-in-out 0s 1 normal forwards;
	@keyframes modal-open {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	&.close {
		animation: modal-close 0.5s ease-in-out 0s 1 normal forwards;
		@keyframes modal-close {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
	}
}
.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 71.2rem;
	max-height: 85vh;
	padding: 3.2rem 2.4rem;
	border: 0.6rem double $default;
	outline: 2rem solid #f3f3f3;
	display: grid;
	grid-template-areas:
		'modal-title'
		'modal-content'
		'modal-button';
	grid-template-rows: auto 1fr auto;
	background: #f3f3f3;
	&.small {
		width: 36rem;
	}
	&::after {
		opacity: 0.8;
		user-select: none;
		pointer-events: none;
		content: '';
		display: block;
		width: calc(100% + 5.2rem);
		height: calc(100% + 5.2rem);
		position: absolute;
		left: -2.6rem;
		top: -2.6rem;
		background: url(../img/paper.png) center/ 100% auto repeat-y;
	}
	&-title {
		grid-area: modal-title;
		text-align: center;
		position: relative;
		padding-bottom: 2rem;
		border-bottom: 0.2rem solid $default;
		margin-bottom: 2.4rem;
	}
	&-content {
		width: 100%;
		grid-area: modal-content;
		overflow: auto;
	}
	&-button-area {
		grid-area: modal-button;
		justify-content: center;
		display: flex;
		gap: 0.8rem;
		margin-top: 2.4rem;
		button {
			flex: 1;
			height: 4.8rem;
			border: 0.4rem double $default;
			font-family: 'ChosunSm', serif;
			transition-duration: 0.5s;
			&:disabled {
				cursor: default;
				opacity: 0.3;
			}
			&:not(:disabled) {
				@include btn-hover;
			}
		}
	}
	.modal-close {
		@include icon(icon-close, 2.8rem);
		position: absolute;
		right: 2.4rem;
		top: 3.2rem;
	}
	@include tablet {
		padding-bottom: 2.4rem;
		&-content {
			margin: 0;
			padding: 0;
			img {
				width: 100%;
			}
		}
		&-title {
			padding-bottom: 1.6rem;
			margin-bottom: 2rem;
		}
		&-button-area {
			margin-top: 2rem;
		}
	}
	@include mobile {
		width: calc(100% - 1.6rem);
		max-height: calc(100vh - 1.6rem);
		height: calc(100vh - 1.6rem);
		min-width: unset;
		outline: 0.8rem solid #f3f3f3;
		&.small {
			width: calc(100% - 1.6rem);
			height: auto;
		}
		&.auto {
			height: auto;
		}
		&-button-area {
			button {
				height: 4rem;
			}
		}
	}
	@media screen and (max-height: 768px) {
		max-height: calc(100vh - 1.6rem);
		height: calc(100vh - 1.6rem);
		min-width: unset;
		outline: 0.8rem solid #f3f3f3;
		&.small {
			width: calc(100% - 1.6rem);
			height: auto;
		}
		&.auto {
			height: auto;
		}
		&-button-area {
			button {
				height: 4rem;
			}
		}
		&::after {
			content: '';
			display: block;
			width: calc(100% + 3.2rem);
			height: calc(100% + 3.2rem);
			position: absolute;
			left: -1.6rem;
			top: -1.6rem;
			background: url(../img/paper.png) center/ 100% auto repeat-y;
		}
	}
}
// ============================== Common  끝  ==============================

.modal {
	// ============================== 뉴스 시작 ==============================
	&.news-modal {
		.modal-title {
			text-align: left;
			padding-right: 3.2rem;
			h3 {
				height: 5.6rem;
			}
			p {
				color: rgba($default, 0.7);
				margin-top: 0.8rem;
			}
		}
		.modal-content {
			> *:not(:first-child, .text + .text) {
				margin-top: 1.6rem;
			}
			img {
				aspect-ratio: 16/9;
				object-fit: cover;
			}
			.text {
				& + .text {
					margin-top: 0.6rem;
				}
			}
		}
		@include mobile {
			.modal-title {
				h3 {
					font-size: 2rem;
					height: auto;
					word-break: break-all;
				}
			}
		}
	}
	// ============================== 뉴스  끝  ==============================

	// ============================== 객실 시작 ==============================
	&.room-modal {
		.modal-title {
			h2 {
				text-transform: capitalize;
				span {
					color: rgba($default, 0.7);
					margin-left: 1.2rem;
				}
			}
		}
		.modal-content {
			.btn-origin {
				display: block;
				position: absolute;
				right: 1.2rem;
				top: 1.2rem;
				z-index: 9;
				width: 10rem;
				line-height: 4rem;
				outline: 0.4rem solid #f3f3f3;
				border: 0.6rem double $default;
				background: #f3f3f3;
				font-family: 'ChosunSm', serif;
				opacity: 0.7;
				text-align: center;
				&::after {
					content: '';
					user-select: none;
					pointer-events: none;
					display: block;
					width: calc(100% + 2rem);
					height: calc(100% + 2rem);
					position: absolute;
					left: -1rem;
					top: -1rem;
					background: url(../img/paper.png) 0 0/ 100% auto repeat-y;
				}
				@include btn-hover;
			}
			.swiper-button-prev,
			.swiper-button-next {
				bottom: calc(50% - 1.4rem);
			}
			.figure {
				position: relative;
				img {
					aspect-ratio: 16/9;
					object-fit: cover;
				}
			}
			h3,
			h4 {
				margin-top: 1.6rem;
			}
			.text {
				margin-top: 0.8rem;
			}
			@include mobile {
				.btn-origin {
					width: 7.2rem;
					line-height: 2.4rem;
					font-size: 1.4rem;
				}
			}
		}
		.modal-close {
			top: 3.7rem;
		}
	}
	// ============================== 객실  끝  ==============================

	//
	&.facility-modal {
		img + .text {
			margin-top: 0.8rem;
		}
		.modal-close {
			top: 3.7rem;
		}
	}
	//
}
