@mixin text-ellipsis($line-clamp: 2) {
	@if ($line-clamp >= 2) {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: $line-clamp;
		-webkit-box-orient: vertical;
	} @else {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@mixin icon($icon-name, $wh: 1.6rem, $color1: '000', $color2: '000') {
	display: block;
	width: $wh;
	height: $wh;
	background: icon($icon-name, $color1, $color2) center / 100% no-repeat;
}

@mixin pseudo-icon($icon-name, $wh: 1.6rem, $color1: '000', $color2: '000') {
	position: relative;
	padding-left: $wh;
	&::before {
		content: '';
		display: block;
		width: $wh;
		height: $wh;
		position: absolute;
		left: 0;
		top: calc(50% - ($wh / 2));
		background: icon($icon-name, $color1, $color2) center / 100% no-repeat;
	}
}

@mixin scrollbar($wh: 2.4rem, $space: 0.8rem) {
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 0.6rem;
		height: $wh;
	}
	&::-webkit-scrollbar-track {
		background: #c5c5c5;
		// background-clip: padding-box;
		// border: $space solid transparent;
		// border-radius: calc($wh / 2);
	}
	&::-webkit-scrollbar-thumb {
		background: rgba($default, 0.4);
		background-clip: padding-box;
		// border: $space solid transparent;
		border-radius: calc($wh / 2);
	}
	&::-webkit-scrollbar:not(body.pc) {
		width: 0 !important;
	}
}

@mixin btn-hover {
	@include hoverable {
		transition-duration: 0.5s;
		&:not(:disabled):hover {
			background-color: rgba($default, 0.6);
			outline-color: rgba($default, 0.6);
			color: $primary;
			border-color: $primary;
		}
	}
}

@mixin hoverable {
	@at-root .pc & {
		@content;
	}
}

@mixin uxga {
	@media screen and (max-width: 1624px) {
		@content;
	}
}

@mixin tablet {
	@media screen and (max-width: 1280px) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: 1024px) {
		@content;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes fadeinout {
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
