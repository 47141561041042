@import './_mixin.scss';
@import './_reset.scss';
@import './_svg.scss';
@import './_swiper.scss';
@import './_daterange.scss';

$default: #000;
$primary: #fff;
// ============================== Common 시작 ==============================

// 로고
i.logo {
	display: block;
	@include icon(logo);
}
// 폰트
h2,
h3,
h4,
.h2,
.h3,
.h4 {
	font-family: 'ChosunSm', serif;
}
h2,
.h2 {
	font-size: 3.2rem;
	@include mobile {
		font-size: 2.8rem;
	}
}
h3,
.h3 {
	font-size: 2.4rem;
}
h4,
.h4 {
	font-size: 2rem;
}
.text {
	word-break: break-all;
	text-align: justify;
	text-indent: 1rem;
	line-height: 130%;
	& + .text {
		margin-top: 0.2rem;
	}
}
b {
	font-family: 'ChosunKg', sans-serif;
}

// 스크롤바
body {
	@include scrollbar;
	overflow-y: scroll;
	overflow-x: hidden;
}
.modal-content {
	@include scrollbar(0.6rem, 0);
	overflow-y: scroll;
	overflow-x: hidden;
}
.table-wrap {
	@include scrollbar(0.6rem, 0);
	overflow-y: hidden;
	overflow-x: scroll;
}

// 피규어(이미지, 캡션)
figure {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	img {
		object-fit: cover;
		aspect-ratio: 16/9;
	}
	figcaption {
		@include pseudo-icon(icon-up-fill);
		@include text-ellipsis(1);
		font-size: 1.4rem;
	}
	&.auto-height-figure {
		height: 100%;
		img {
			height: 0.1rem;
			flex-grow: 1;
		}
	}
	@include mobile {
		&.auto-height-figure {
			img {
				height: auto;
			}
		}
	}
}

// 높이조절 요소
.auto-height {
	display: flex;
	flex-direction: column;
	height: calc(100% - 5rem);
	gap: 2rem;
	&-component {
		height: 0.1rem;
		flex-grow: 1;
	}
	&.info {
		gap: 2.4rem;
		height: 100%;
	}
}

// 디자인된 보더
.designed-border {
	position: relative;
	display: block;
	min-height: 6rem;
	.border-top,
	.border-bottom {
		&::before,
		&::after {
			content: '';
			position: absolute;
			@include icon(border-coner, 2.1rem);
		}
	}
	.border-top::before {
		left: 0;
		top: 0;
	}
	.border-top::after {
		right: 0;
		top: 0;
		transform: rotate(90deg);
	}
	.border-bottom::after {
		right: 0;
		bottom: 0;
		transform: rotate(180deg);
	}
	.border-bottom::before {
		left: 0;
		bottom: 0;
		transform: rotate(270deg);
	}
	.border-inner {
		transition-duration: 0.5s;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		clip-path: polygon(
			2.1rem 0%,
			calc(100% - 2.1rem) 0%,
			calc(100% - 2.1rem) 2.1rem,
			100% 2.1rem,
			100% calc(100% - 2.1rem),
			calc(100% - 2.1rem) calc(100% - 2.1rem),
			calc(100% - 2.1rem) 100%,
			2.1rem 100%,
			2.1rem calc(100% - 2.1rem),
			0% calc(100% - 2.1rem),
			0% 2.1rem,
			2.1rem 2.1rem
		);
		border: 0.6rem double $default;
	}
}

// 메뉴버튼
.btn-menu {
	position: relative;
	width: 3.6rem;
	height: 3.6rem;
	span {
		display: block;
		width: 100%;
		height: 0.2rem;
		position: absolute;
		left: 0;
		top: calc(50% - 0.1rem);
		background: $default;
		transition-duration: 0.5s;
	}
	&::before,
	&::after {
		content: '';
		display: block;
		width: 50%;
		height: 0.2rem;
		position: absolute;
		background: $default;
		transition-duration: 0.5s;
	}
	&::before {
		left: 0;
		top: calc(25% - 0.1rem);
	}
	&::after {
		right: 0;
		bottom: calc(25% - 0.1rem);
	}
	&.active {
		span {
			transform: rotate(-45deg);
		}
		&::before {
			transform: rotate(45deg);
			left: 0.3rem;
			top: calc(25% + 0.2rem);
		}
		&::after {
			transform: rotate(45deg);
			right: 0.3rem;
			bottom: calc(25% + 0.2rem);
		}
	}
}

.loading {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	background-color: #cbcbcb;
	.spinner {
		position: absolute;
		left: calc(50% - 5.5rem);
		top: calc(50% - 5.5rem);
		width: 11rem;
		height: 11rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgba($default, 0.5);
		animation: fadeinout 3s linear infinite;
		letter-spacing: -0.02rem;
		font-family: 'Helvetica', 'Arial', 'Verdana', sans-serif;
		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			border: 0.2rem solid;
			border-radius: 50%;
		}
		&::before {
			border-color: transparent rgba($default, 0.5);
			width: 100%;
			height: 100%;
			animation: rotation 3s linear infinite reverse;
		}
		&::after {
			content: '';
			border-color: rgba($default, 0.5) transparent;
			width: 120%;
			height: 120%;
			left: -10%;
			top: -10%;
			animation: rotation 3s linear infinite;
		}
	}
	// &.active {
	// animation: fade-out 1s linear 3.5s 1 forwards;
	// }

	& + #wrap {
		height: 0;
		overflow: hidden;
	}

	// svg
	// svg {
	// 	position: absolute;
	// 	left: 50%;
	// 	top: 50%;
	// 	transform: translate(-50%, -50%);
	// 	text {
	// 		font-family: 'ChosunKm', serif;
	// 		font-size: 4.8rem;
	// 		fill: transparent;
	// 		stroke-dasharray: 19rem;
	// 		animation: stroke 1s linear;
	// 		animation-fill-mode: forwards;
	// 		@for $i from 0 through 5 {
	// 			&:nth-child(#{$i + 1}) {
	// 				animation-delay: calc(#{$i} * 0.5s);
	// 			}
	// 		}
	// 		stroke: rgba($default, 0.6);
	// 		stroke-width: 0.1rem;
	// 		stroke-dashoffset: -19rem;
	// 	}
	// }
	// @keyframes stroke {
	// 	80% {
	// 		fill: transparent;
	// 	}
	// 	100% {
	// 		stroke-dashoffset: 0;
	// 		fill: rgba($default, 0.8);
	// 	}
	// }
}

.checkbox {
	position: relative;
	input[type='checkbox'] {
		position: absolute;
		top: 0;
		left: 0;
		border: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		cursor: pointer;
		& + label {
			position: relative;
			&::before {
				content: '';
				display: block;
				width: 2.4rem;
				height: 2.4rem;
				@include icon(icon-checkbox, 2.4rem);
			}
		}
		&:checked + label::before {
			@include icon(icon-checkbox-checked, 2.4rem, 'fff');
		}
		&:disabled,
		&:disabled:checked {
			& + label {
				&::before {
					opacity: 0.3;
				}
			}
		}
	}
}
// ============================== Common  끝  ==============================

// ============================== 타이틀 시작 ==============================
.title {
	.pattern {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		gap: 0.8rem;
		i.title-pattern {
			@include icon(title-pattern);
			width: 40rem;
			height: 10rem;
			margin-top: calc(-10rem + 2.4rem);
		}
		p {
			width: calc(50% - 20.8rem);
			flex: 1;
			font-family: 'ChosunLo', serif;
			font-size: 1.8rem;
			height: 2.16rem;
			display: flex;
			justify-content: space-between;
			strong {
				display: none;
			}
		}
	}
	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		border-top: 0.2rem solid $default;
		border-bottom: 0.6rem double $default;
		padding: 2rem 0;
		margin-top: 1rem;
		.h1 {
			font-family: 'ChosunKm', serif;
			text-align: center;
			font-size: 8rem;
			text-transform: uppercase;
			flex: 1;
			&.ls5 {
				letter-spacing: -0.05rem;
			}
		}
		.home,
		.date {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: 'ChosunKg', sans-serif;
			width: 24rem;
			border: 0.6rem double $default;
		}
		.home {
			gap: 1.2rem;
			.logo {
				width: 4rem;
				height: 4rem;
			}
		}
		.date {
			flex-direction: column;
			.month {
				text-align: center;
				font-size: 2rem;
			}
			.year {
				text-align: center;
				font-size: 3.6rem;
			}
			.btn-menu {
				width: 3.2rem;
				height: 3.2rem;
				display: none;
			}
		}
	}
	@include uxga {
		.pattern {
			i.title-pattern {
				width: 32rem;
				height: 8rem;
				margin-top: calc(-8rem + 2.1rem);
			}
			p {
				width: calc(50% - 16.8rem);
				span.ux {
					display: none;
				}
			}
		}
	}
	@media screen and (max-width: 1452px) {
		.pattern {
			p {
				font-size: 1.75rem;
				height: 2.1rem;
				span.ut {
					display: none;
				}
			}
		}
	}
	@include tablet {
		.pattern {
			i.title-pattern {
				width: 28rem;
				height: 7rem;
				margin-top: calc(-7rem + 2.16rem);
			}
			p {
				width: calc(50% - 14.8rem);
				font-size: 1.46rem;
				height: 1.752rem;
				span.ta {
					display: none;
				}
			}
		}
		&-inner {
			.h1 {
				font-size: 6rem;
			}
			.home,
			.date {
				width: 7.2rem;
				aspect-ratio: 1/1;
				border: none;
			}
			.home {
				.logo {
					width: 5.2rem;
					height: 5.2rem;
				}
				> div {
					display: none;
				}
			}
			.date {
				.year {
					font-size: 2.8rem;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.pattern {
			p {
				letter-spacing: -0.01rem;
				span.tm {
					display: none;
				}
			}
		}
	}
	@include mobile {
		.pattern {
			i.title-pattern {
				width: 14rem;
				height: 3.5rem;
				margin-top: calc(-7rem + 1.44rem);
			}
			p {
				width: calc(50% - 7.8rem);
				span {
					display: none;
				}
				strong {
					display: block;
					width: 100%;
					text-align: justify;
					text-align: center;
					&::after {
						content: '';
						display: inline-block;
						width: 100%;
					}
				}
			}
		}
		&-inner {
			align-items: center;
			padding: 1.2rem 0;
			.h1 {
				font-size: 2.6rem;
				line-height: 3.2rem;
			}
			.home,
			.date {
				width: 3.2rem;
			}
			.home {
				border: none;
			}
			.date {
				border: none;
				.month,
				.year {
					display: none;
				}
				.btn-menu {
					display: block;
				}
			}
		}
	}
	@media screen and (max-width: 544px) {
		.pattern {
			p {
				text-align: center;
				font-size: 1.42rem;
				strong i {
					display: none;
				}
			}
		}
	}
}
// ============================== 타이틀  끝  ==============================

// ============================== 헤더 시작 ==============================
header {
	position: fixed;
	left: 0;
	top: -6rem;
	width: 100%;
	background: #f3f3f3;
	z-index: 999;
	transition: top 0.5s;
	.header-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 6rem;
		width: 160rem;
		margin: 0 auto;
		.home {
			.logo {
				width: 3.6rem;
				height: 3.6rem;
			}
		}
		.h2 {
			text-transform: uppercase;
			font-family: 'ChosunKm', serif;
		}
	}
	&::after {
		user-select: none;
		pointer-events: none;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: url(../img/paper.png) 0 0/ 100% auto repeat-y;
	}
	&.active {
		top: 0;
		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 0.6rem;
			position: absolute;
			left: 0;
			top: 100%;
			background: linear-gradient(180deg, rgba($default, 0.5) 0%, transparent 100%);
		}
	}
	.menu {
		height: 0;
		overflow: hidden;
		transition-duration: 0.5s;
		&.active {
			height: 4rem;
		}
		.gnb ul {
			border-bottom: none;
		}
	}
	@include uxga {
		.header-inner {
			width: 100%;
			padding: 0 2.4rem;
		}
	}
	@include mobile {
		.header-inner {
			.home {
				.logo {
					width: 3.2rem;
					height: 3.2rem;
				}
			}
			.h2 {
				font-size: 2.4rem;
			}
			.btn-menu {
				width: 3.2rem;
				height: 3.2rem;
			}
		}
		.menu {
			.gnb ul li {
				flex-basis: 33.33%;
			}
			&.active {
				height: 6.4rem;
			}
		}
	}
}
// ============================== 헤더  끝  ==============================

// ============================== 푸터 시작 ==============================
.footer {
	display: flex;
	gap: 2.4rem;
	margin-top: 2.4rem;
	padding-top: 1.6rem;
	border-top: 0.6rem double $default;
	.footer-logo {
		flex: 1;
		flex-basis: 30rem;
		border: 0.6rem double $default;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		padding: 2rem 0;
		.logo {
			width: 4rem;
			height: 4rem;
		}
		div {
			p {
				font-family: 'ChosunKg', sans-serif;
			}
		}
	}
	.footer-info {
		flex: 3;
		flex-basis: 90rem;
		position: relative;
		border: 0.2rem solid $default;
		text-align: center;
		padding: 2rem 0;
		.designed-border {
			position: absolute;
			left: 2rem;
			top: 2rem;
			width: 12rem;
			height: calc(100% - 4rem);
		}
	}
	.btn-shortcut {
		flex: 1;
		flex-basis: 30rem;
		text-align: center;
		border: 0.6rem double $default;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 7.2rem;
	}
	.ta-cont {
		display: none;
	}
	@include tablet {
		.ta-cont {
			display: flex;
		}
		.pc-cont {
			display: none;
		}
		.footer-logo {
			flex-direction: column;
			text-align: center;
		}
		.footer-info {
			padding: 0;
			border: 0;
			.wrap {
				border: 0.2rem solid $default;
				margin-top: 1.2rem;
				padding: 1.2rem 0;
			}
			.designed-border {
				position: relative;
				inset: unset;
				width: 100%;
				height: 6.4rem;
			}
		}
	}
	@include mobile {
		display: block;
		margin-top: 2.4rem;
		padding-top: 1.6rem;
		.footer-logo {
			flex-direction: row;
			border: none;
			padding: 0;
		}
		.footer-info {
			padding: 0;
			.designed-border {
				margin-top: 0.8rem;
			}
			.wrap {
				border: none;
				margin-top: 0;
			}
		}
		.designed-border {
			margin: 0.8rem auto 0;
			.border-inner {
				line-height: 9.6rem;
			}
		}
	}
}
.footer-copy {
	margin-top: 2.4rem;
	border-top: 0.2rem solid $default;
	text-align: center;
	padding: 2rem 0;
	@include tablet {
		margin-top: 1.6rem;
	}
}
// ============================== 푸터  끝  ==============================

// ============================== 내비게이션바 시작 ==============================
.gnb {
	ul {
		overflow: hidden;
		display: flex;
		justify-content: space-around;
		border-bottom: 0.2rem solid $default;
		font-family: 'ChosunSm', serif;
		text-align: center;
		transition-duration: 0.5s;
		flex-wrap: wrap;
		> li {
			position: relative;
			flex: 1;
			flex-basis: 16.66%;
			a {
				display: block;
				height: 4rem;
				position: relative;
				text-transform: uppercase;
				font-size: 1.8rem;
				span {
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					transition-duration: 0.5s;
					&.ko {
						opacity: 0;
					}
				}
				&.active {
					font-family: 'ChosunKm', serif;
					.en {
						opacity: 0;
					}
					.ko {
						opacity: 1;
					}
				}
				@include hoverable {
					&:hover {
						font-family: 'ChosunKm', serif;
						.en {
							opacity: 0;
						}
						.ko {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	@include mobile {
		&:not(header .gnb) {
			overflow: hidden;
			ul {
				height: 0;
				transition-duration: 0.5s;
				border-bottom-color: transparent;
				li {
					flex-basis: 33.33%;
				}
			}
			&.active {
				ul {
					border-bottom-color: $default;
				}
			}
		}
		&.active ul {
			height: 6.4rem !important;
		}
		ul li a {
			font-size: 1.6rem;
			height: 3.2rem;
		}
	}
	@media (max-width: 400px) {
		ul li a {
			letter-spacing: -0.1rem;
		}
	}
}
// ============================== 내비게이션바  끝  ==============================

// ============================== 루트 시작 ==============================
#root {
	position: relative;
	background: #e5e5e5;
	width: 100%;
	&::after {
		opacity: 0.8;
		user-select: none;
		pointer-events: none;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: url(../img/paper.png) center top/ 100% auto repeat-y;
	}
	#wrap {
		max-width: 160rem;
		min-height: 100vh;
		padding: 9.8rem 0 0;
		margin: 0 auto;
		.content {
			display: grid;
			grid-template-columns: 1fr 3fr 1fr;
			gap: 2.4rem;
			padding-top: 4rem;
			> * {
				.title {
					margin-bottom: 1.2rem;
					&.with-viewmore {
						position: relative;
						display: flex;
						justify-content: space-between;
						align-items: center;
						a {
							color: #666;
						}
					}
				}
				.sub-title {
					margin-bottom: 1.2rem;
				}
			}
			> *:not([class*='-figure'], .rooms-text, .gallery, .around-tabs, .book-info, .btn-book),
			.info-reservation {
				padding-top: 1.6rem;
				border-top: 0.2rem solid $default;
			}
		}
		@include uxga {
			padding: 7.8rem 2.4rem 0;
		}
		@include tablet {
			padding-top: 6.9rem;
			.content {
				grid-template-columns: 1fr 2fr 1fr;
				padding-top: 2.4rem;
				gap: 1.6rem;
			}
		}
		@include mobile {
			padding: 3.9rem 1.6rem 0;
			.content {
				display: flex;
				flex-direction: column;
				padding-top: 1.6rem;
				> *:not([class*='-figure'], .rooms-text, .gallery, :first-child),
				.info-reservation {
					margin-top: 1.6rem;
					padding-top: 1.2rem;
				}
			}
		}
	}
}

// ============================== 루트  끝  ==============================

.inp-cell {
	position: relative;
	input,
	textarea,
	select {
		display: block;
		width: 100%;
		resize: none;
		padding: 0.8rem 1.2rem;
		border: 0.1rem solid rgba($default, 0.5);
		border-radius: 0.4rem;
		&:focus {
			padding: 0.7rem 1.1rem;
			border: 0.2rem solid $default;
			& + .place-holder {
				font-size: 1.2rem;
				top: -0.7rem;
				left: 1.5rem;
				color: $default;
			}
		}
	}
	.place-holder {
		position: absolute;
		display: block;
		left: 1.3rem;
		top: 1.1rem;
		font-size: 1.4rem;
		color: rgba($default, 0.5);
		pointer-events: none;
		transition-duration: 0.3s;
	}
	& + .chk {
		margin-top: 0.4rem;
		font-size: 1.3rem;
		color: rgba($default, 0.7);
	}
	&.name {
		input:focus {
			clip-path: polygon(0% 0%, 1.2rem 0%, 1.2rem 0.3rem, 6.37rem 0.3rem, 6.37rem 0%, 100% 0%, 100% 100%, 0% 100%);
		}
	}
	&.phone {
		display: grid;
		grid-template-columns: 8rem 1fr 1fr;
		gap: 0.8rem;
	}
	&.email {
		input:focus {
			clip-path: polygon(0% 0%, 1.2rem 0%, 1.2rem 0.3rem, 5.17rem 0.3rem, 5.17rem 0%, 100% 0%, 100% 100%, 0% 100%);
		}
	}
	&.request {
		textarea:focus {
			clip-path: polygon(0% 0%, 1.2rem 0%, 1.2rem 0.3rem, 6.37rem 0.3rem, 6.37rem 0%, 100% 0%, 100% 100%, 0% 100%);
		}
	}
}
.table-wrap {
	overflow: overlay;
}
table {
	border-top: 0.6rem double $default;
	tbody,
	thead {
		tr {
			th,
			td {
				border-bottom: 0.1rem solid #666;
				padding: 1.2rem 0.8rem;
			}
			th {
				font-family: 'ChosunSm', serif;
			}
		}
		&.alC,
		.alC {
			text-align: center;
		}
		&.alL,
		.alL {
			text-align: left;
		}
		&.alR,
		.alR {
			text-align: right;
		}
	}
	&.small {
		border-top: 0.2rem solid $default;
		tbody,
		thead {
			tr {
				th,
				td {
					padding: 0.8rem 0.4rem;
					font-size: 1.4rem;
				}
			}
		}
	}
}

.accord-wrap {
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	border-top: 0.1rem solid $default;
	padding-top: 0.2rem;
	.accord-panel {
		border-top: 0.1rem solid transparent;
		border-bottom: 0.1rem solid $default;
		overflow: hidden;
		transition-duration: 0.5s;
		.accord-title {
			display: block;
			width: 100%;
			text-align: left;
			padding: 1rem 4.4rem 1.2rem 1.2rem;
			position: relative;
			transition-duration: 0.5s;
			> * {
				color: inherit;
			}
			@include hoverable {
				&:hover {
					background: rgba($default, 0.8);
					color: $primary;
					&::before,
					&::after {
						background-color: $primary;
					}
				}
			}
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				background-color: $default;
				border-radius: 0.1rem;
				transition-duration: 0.5s;
			}
			&::before {
				width: 0.2rem;
				height: 2rem;
				right: 2.1rem;
				top: calc(50% - 1.1rem);
				opacity: 1;
			}
			&::after {
				width: 2rem;
				height: 0.2rem;
				right: 1.2rem;
				top: calc(50% - 0.2rem);
			}
		}
		.accord-content {
			max-height: 0;
			background-color: rgba($default, 0.05);
			transition-duration: 0.5s;
			transition-timing-function: ease-in-out;
			overflow: hidden;
			p {
				padding: 2.4rem;
			}
		}
		&.active {
			border-color: $default;
			.accord-title {
				background: rgba($default, 0.8);
				color: $primary;
				&::before {
					transform: rotate(-90deg);
					background-color: $primary;
				}
				&::after {
					background-color: $primary;
				}
			}
			.accord-content {
				max-height: 100vh;
			}
		}
	}
}

// list
.list-style {
	text-align: left;
	& + .list-style {
		margin-top: 1.2rem;
	}
	li {
		// 공통
		position: relative;
		word-break: break-all;
		color: $default;
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			color: inherit;
			font-size: inherit;
		}
		// 간격
		& + li {
			// 1depth
			margin-top: 0.6rem;
		}
		ul {
			// 2depth
			margin-top: 0.4rem;
			li + li {
				margin-top: 0.4rem;
			}
		}
		&.bul {
			padding-left: 0.9rem;
			&::before {
				width: 0.3rem;
				height: 0.3rem;
				border-radius: 50%;
				background: $default;
				top: 0.9rem;
			}
		}
		&.hyp {
			padding-left: 1.1rem;
			&::before {
				content: '-';
			}
		}
		&.ref {
			padding-left: 1.5rem;
			&::before {
				content: '※';
			}
		}
		&.star {
			padding-left: 1.3rem;
			color: rgba($default, 0.8);
			&::before {
				content: '*';
			}
		}
		.table-wrap {
			margin-top: 0.6rem;
		}
	}
}
