@import './_mixin.scss';
@import './_svg.scss';

$default: #000;
$primary: #fff;

#root #wrap .content {
	// ============================== 홈 시작 ==============================
	&.main {
		grid-template-areas:
			'main-slide main-slide visual-text'
			'info rooms-tit rooms-tit'
			'info rooms-figure rooms-text'
			'info around faq'
			'news news news';
		.main-slide {
			grid-area: main-slide;
		}
		.visual-text {
			grid-area: visual-text;
			margin-bottom: 2rem;
			.sub-title {
				@include text-ellipsis(3);
				word-break: break-all;
			}
			.btn-viewall {
				display: block;
				margin-top: 1.2rem;
				text-align: center;
				.border-inner {
					padding: 2.8rem 0;
				}
				.pb {
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: left;
					margin-bottom: 0.8rem;
					gap: 0.4rem;
					.logo {
						height: 3.6rem;
						width: 3.6rem;
					}
					div {
						p {
							font-family: 'ChosunSm', serif;
						}
					}
				}
				span {
					font-family: 'ChosunSm', serif;
					font-size: 1.8rem;
				}
			}
			.progress {
				margin-bottom: 2rem;
			}
			@include mobile {
				.sub-title {
					-webkit-line-clamp: 2;
				}
			}
		}

		.rooms-tit {
			grid-area: rooms-tit;
			.tab-title {
				display: flex;
				button {
					flex: 1;
					transition-duration: 0.5s;
					height: 5rem;
					border: solid $default;
					border-width: 0.2rem 0.1rem;
					padding: 0 0.4rem;
					&:first-child {
						border-left-width: 0.2rem;
					}
					&:last-child {
						border-right-width: 0.2rem;
					}
					@include hoverable {
						&:not(.active):hover {
							background: rgba($default, 0.2);
						}
					}
					&.active {
						color: $primary;
						background: rgba($default, 0.8);
					}
					span {
						color: inherit;
						font-size: inherit;
						&.en {
							font-size: 2rem;
							margin-right: 0.4rem;
							text-transform: capitalize;
						}
						&.ko {
							font-size: 1.6rem;
						}
					}
				}
			}
			@include mobile {
				.tab-title {
					align-items: center;
					button {
						height: 4rem;
						.en {
							display: none;
						}
						.ko {
							position: relative;
							top: -0.3rem;
							font-size: 2rem;
						}
					}
				}
			}
		}
		.rooms-figure {
			grid-area: rooms-figure;
			position: relative;
			.auto-height-figure {
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				width: 100%;
				transition-duration: 0.5s;
				&.active {
					opacity: 1;
				}
			}
			@include mobile {
				aspect-ratio: 16/9;
				margin-top: 1.2rem;
			}
		}
		.rooms-text {
			grid-area: rooms-text;
			position: relative;
			margin-bottom: 2rem;
			> div {
				margin-top: 1.2rem;
				&:first-child {
					margin-top: 0;
				}
				h3 + .text {
					height: 10rem;
				}
				.text {
					margin-top: 0.8rem;
				}
			}
			.control {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2rem;
				border: 0.6rem double $default;
				font-family: 'ChosunLo', sans-serif;
				font-weight: 500;
				button {
					width: 2rem;
					height: 2rem;
					font-family: 'ChosunLo', sans-serif;
					font-weight: 500;
				}
			}
			@include uxga {
				> div h3 + .text {
					height: auto;
					@include text-ellipsis(5);
				}
			}
			@include tablet {
				.control {
					padding: 1.2rem;
				}
			}
			@include mobile {
				margin-top: 1.2rem;
				.control {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					margin-top: 0;
				}
				> div:first-child {
					padding-top: 7.6rem;
				}
			}
		}

		.info {
			grid-area: info;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 2.4rem;
			.info-waytocome {
				.map {
					margin-bottom: 1.2rem;
					* {
						cursor: default;
					}
				}
				.h4.text {
					.btn-copy {
						@include icon(icon-copy, 2rem);
						display: inline-block;
						margin-left: 0.4rem;
						margin-top: 0.3rem;
						vertical-align: top;
					}
				}
			}
			.auto-height-component {
				.border-inner {
					&.ta-cont {
						display: none;
					}
					@include tablet {
						&.ta-cont {
							display: flex;
						}
						&.pc-cont {
							display: none;
						}
					}
				}
			}
			@include mobile {
				gap: 0;
				.auto-height-component {
					margin-top: 1.2rem;
				}
			}
		}

		.around {
			grid-area: around;
			&-list {
				width: 100%;
				margin-top: 1.2rem;
				display: flex;
				gap: 1.2rem;
				> li {
					flex: 1;
					width: calc(50% - 0.6rem);
					.around-text {
						margin-top: 0.6rem;
						h4 {
							@include text-ellipsis(1);
							margin-bottom: 0.4rem;
						}
						.source {
							font-size: 1.4rem;
							margin-top: 0.4rem;
							color: #666;
							text-align: right;
							span {
								color: #666;
							}
						}
						.text {
							@include text-ellipsis(3);
						}
					}
				}
			}
			@include mobile {
				&-list > li .around-text .source .pc {
					display: none;
				}
			}
		}

		.faq {
			grid-area: faq;
			h4 {
				word-break: break-all;
			}
		}

		.news {
			grid-area: news;
			display: flex;
			flex-wrap: wrap;
			gap: 4rem;
			padding-top: 2.4rem;
			> button {
				position: relative;
				flex: 1;
				display: flex;
				gap: 1rem;
				text-align: left;
				&:not(:last-child)::after {
					content: '';
					display: block;
					width: 0.2rem;
					height: 100%;
					position: absolute;
					right: -2rem;
					top: 0;
					background-color: $default;
				}
				.thumb {
					width: 20rem;
					img {
						aspect-ratio: 1/1;
						object-fit: cover;
					}
				}
				.cont {
					flex: 1;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 0.2rem;
					.tit {
						@include text-ellipsis(2);
						height: 4.8rem;
					}
					.date {
						color: #666;
						text-align: right;
						position: relative;
						font-size: 1.4rem;
						&::before {
							content: '';
							display: block;
							width: calc(100% - 7rem);
							height: 0.1rem;
							border-top: 0.1rem dashed $default;
							position: absolute;
							left: 0;
							top: calc(50% - 0.05rem);
						}
					}
					.text {
						font-size: 1.4rem;
						@include text-ellipsis(6);
					}
				}
				&:first-child h4 {
					position: relative;
					padding-right: 2.2rem;
					&::before {
						content: 'N';
						display: block;
						width: 2rem;
						line-height: 2rem;
						border-radius: 0.4rem;
						text-align: center;
						position: absolute;
						right: 0;
						top: 0.4rem;
						font-size: 1.4rem;
						background-color: #ff6259;
						font-weight: 700;
						font-family: 'ChosunLo', sans-serif;
						color: #fff;
					}
				}
			}
			@include tablet {
				> button {
					flex-direction: column;
					.thumb {
						width: 100%;
						img {
							aspect-ratio: 16/9;
						}
					}
					.cont .text {
						-webkit-line-clamp: 5;
					}
					&:nth-child(2)::after,
					&:last-child {
						display: none;
					}
				}
			}
			@include mobile {
				> button .cont .text {
					-webkit-line-clamp: 4;
				}
			}
		}
		@include tablet {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	// ============================== 홈  끝  ==============================

	// ============================== 펜션소개 시작 ==============================
	&.about {
		grid-template-areas:
			'gallery gallery gallery'
			'visual visual visual'
			'waytocome waytocome facilities';

		.gallery {
			grid-area: gallery;
			position: relative;
			&-images {
				height: calc(100% - 15%);
				max-height: 80rem;
				.swiper-button-prev,
				.swiper-button-next {
					bottom: calc(50% - 1.4rem);
				}
				img {
					height: 100%;
					object-fit: cover;
				}
				.btn-origin {
					display: block;
					position: absolute;
					right: 1.2rem;
					top: 1.2rem;
					z-index: 9;
					width: 12rem;
					line-height: 6rem;
					border: 0.6rem double $default;
					background: #f3f3f3;
					outline: 0.6rem solid #f3f3f3;
					font-family: 'ChosunSm', serif;
					text-align: center;
					&::after {
						content: '';
						user-select: none;
						pointer-events: none;
						display: block;
						width: calc(100% + 2.4rem);
						height: calc(100% + 2.4rem);
						position: absolute;
						left: -1.2rem;
						top: -1.2rem;
						background: url(../img/paper.png) 0 0/ 100% auto repeat-y;
					}
					@include btn-hover;
				}
			}
			&-thumbs {
				height: 15%;
				img {
					height: 100%;
					object-fit: cover;
				}
			}
			@include tablet {
				.btn-origin {
					width: 10rem;
					line-height: 4rem;
					outline: 0.4rem solid #f3f3f3;
					&::after {
						width: calc(100% + 2rem);
						height: calc(100% + 2rem);
						left: -1rem;
						top: -1rem;
					}
				}
			}
			@include mobile {
				.btn-origin {
					width: 7.2rem;
					line-height: 2.4rem;
					font-size: 1.4rem;
				}
			}
		}

		.visual {
			grid-area: visual;
			text-align: center;
			padding: 6rem 0 3.6rem 0;
		}

		.waytocome {
			grid-area: waytocome;
			.sub-title {
				margin-top: 1.2rem;
			}
		}

		.facilities {
			grid-area: facilities;
			.facility-list {
				display: grid;
				height: calc(100% - 5rem);
				grid-template-columns: repeat(2, 1fr);
				text-align: center;
				align-items: center;
				align-content: stretch;
				li {
					justify-self: center;
					i {
						display: block;
						margin: 0 auto;
						width: 4rem;
						height: 4rem;
						margin-bottom: 0.8rem;
						@each $class in wifi, cooker, parking, bbq, shampoo, air, refrigerator, shower, dryer, dog, cooking, microwave {
							&.icon-#{$class} {
								background: icon(icon-#{$class}, '000') center / 3.6rem no-repeat;
							}
						}
					}
					p {
						font-size: 1.4rem;
					}
				}
			}
		}
		@include mobile {
			.facilities {
				.facility-list {
					grid-template-columns: repeat(3, 1fr);
					row-gap: 2.4rem;
				}
			}
		}
	}
	// ============================== 펜션소개  끝  ==============================

	// ============================== 객실소개 시작 ==============================
	&.rooms {
		grid-template-columns: repeat(4, 1fr);
		.detail {
			h2 {
				text-align: left;
				text-transform: capitalize;
				.h3 {
					color: #666;
				}
			}
			button {
				.roomData-figure {
					position: relative;
					overflow: hidden;
					img {
						transition-duration: 0.5s;
					}
					img:nth-child(2) {
						position: absolute;
						left: 100%;
						top: 0;
					}
				}
				@include hoverable {
					&:hover .roomData-figure {
						img {
							transform: translateX(-100%);
						}
					}
				}
				figcaption {
					text-align: left;
				}
				.cont {
					margin-top: 1.2rem;
					p {
						margin-top: 0.4rem;
					}
				}
			}
		}
		.map {
			grid-column: 1 /5;
			position: relative;
			.map-figure {
				position: relative;
				i.img {
					display: block;
					aspect-ratio: 16/9;
					background: url(../img/overview.jpg) center / cover no-repeat;
				}
			}
			.btn-modal button,
			.info-content button i,
			.btn-modal div,
			.info-content div i {
				display: block;
				width: 2.6rem;
				line-height: 2.6rem;
				text-align: center;
				border-radius: 50%;
				font-size: 1.5rem;
				font-family: 'ChosunLo', sans-serif;
				text-transform: uppercase;
				@include hoverable {
					&:not(div):hover {
						&:not(button i)::before,
						&:not(button i)::after {
							content: '';
							display: block;
							width: 3rem;
							height: 3rem;
							position: absolute;
							left: calc(50% - 1.5rem);
							top: calc(50% - 1.5rem);
							border: 0.1rem solid $primary;
							border-radius: 50%;
							box-sizing: border-box;
							opacity: 0;
							@keyframes hover {
								from {
									transform: scale(1);
									opacity: 0;
								}
								10% {
									opacity: 1;
								}
								to {
									transform: scale(2);
									opacity: 0;
								}
							}
						}
						&:not(button i)::before {
							animation: hover 1s linear 0s infinite;
						}
						&:not(button i)::after {
							animation: hover 1s linear 0.3s infinite;
						}
					}
				}
				&[class^='s'] {
					background-color: #a9e3ee;
				}
				&[class^='c'] {
					background-color: #dddddd;
				}
				&[class^='m'] {
					background-color: #ff7f80;
				}
				&[class^='v'] {
					background-color: #b0c4e9;
				}
				&.ex {
					background-color: $default;
					color: $primary;
				}
			}
			.info-list {
				position: absolute;
				left: 2rem;
				bottom: 4rem;
				width: 26rem;
				padding: 0.8rem;
				background-color: rgba($primary, 0.8);
				z-index: 1;
				display: flex;
				flex-direction: column;
				gap: 0.8rem;
				.info {
					&-title {
						padding: 0.6rem;
						border-radius: 1.55rem;
						background: rgba($default, 0.7);
						color: $primary;
						margin-bottom: 0.6rem;
						display: flex;
						gap: 0.4rem;
						align-items: center;
						justify-content: center;
						strong {
							font-family: 'ChosunKm', serif;
							text-transform: uppercase;
							font-size: 1.6rem;
							&.vally {
								color: #b0c4e9;
							}
							&.sky {
								color: #a9e3ee;
							}
							&.mountain {
								color: #ff7f80;
							}
							&.cloud {
								color: #dddddd;
							}
						}
						span {
							color: $primary;
							font-size: 1.4rem;
						}
						&:first-child {
							margin-top: 0;
						}
					}
					&-content {
						display: flex;
						flex-wrap: wrap;
						button {
							display: flex;
							flex-basis: 50%;
							align-items: center;
							gap: 0.4rem;
							margin-top: 0.4rem;
							text-transform: uppercase;
						}
					}
					&.ex {
						.info-content {
							@include tablet {
								button {
									flex-basis: calc(100% / 3);
								}
							}
							@media (max-width: 360px) {
								flex-basis: calc(100% / 3);
							}
						}
					}
				}
			}
			.btn-modal {
				button,
				div {
					position: absolute;
					transition-duration: 0.5s;
					&.s1 {
						left: 30%;
						top: 65%;
					}
					&.c1 {
						left: 33.5%;
						top: 63.5%;
					}
					&.c2 {
						left: 37%;
						top: 62%;
					}
					&.s2 {
						left: 40.5%;
						top: 60.5%;
					}

					&.v1 {
						left: 31.75%;
						top: 70%;
					}
					&.v2 {
						left: 35.25%;
						top: 68.5%;
					}
					&.m {
						left: 38.75%;
						top: 67%;
					}

					&.s3 {
						left: 57%;
						top: 47.5%;
					}
					&.c3 {
						left: 63.33%;
						top: 44.16%;
					}
					&.c4 {
						left: 69.66%;
						top: 40.83%;
					}
					&.s4 {
						left: 76%;
						top: 37.5%;
					}

					&.p1 {
						left: 27%;
						top: 69%;
					}
					&.p2 {
						left: 47%;
						top: 66%;
					}
					&.t {
						left: 51.5%;
						top: 64.5%;
					}
					&.o {
						left: 62%;
						top: 58%;
					}
					&.p3 {
						left: 92%;
						top: 41%;
					}
				}
			}
		}
		@include tablet {
			grid-template-columns: repeat(2, 1fr);
			.map {
				grid-column: 1 /3;
				.map-figure i.img {
					background-image: url(../img/overview_tablet.jpg);
				}
				.info-list {
					flex-direction: row;
					flex-wrap: wrap;
					position: relative;
					left: 0;
					top: 0;
					width: 100%;
					background: none;
					padding: 0;
					margin-bottom: 1.2rem;
					.info {
						flex: 1;
						flex-basis: 24rem;
						&-title {
							background: rgba($default, 0.6);
						}
					}
				}
				.btn-modal {
					button,
					div {
						&.s1 {
							left: 10%;
							top: 70%;
						}
						&.c1 {
							left: 13.5%;
							top: 68.5%;
						}
						&.c2 {
							left: 17%;
							top: 67%;
						}
						&.s2 {
							left: 20.5%;
							top: 65.5%;
						}

						&.v1 {
							left: 11.75%;
							top: 76%;
						}
						&.v2 {
							left: 15.25%;
							top: 74.5%;
						}
						&.m {
							left: 18.75%;
							top: 73%;
						}

						&.s3 {
							left: 48%;
							top: 45%;
						}
						&.c3 {
							left: 54.33%;
							top: 41.33%;
						}
						&.c4 {
							left: 60.66%;
							top: 37.66%;
						}
						&.s4 {
							left: 67%;
							top: 34%;
						}

						&.p1 {
							left: 7%;
							top: 75%;
						}
						&.p2 {
							left: 30%;
							top: 73%;
						}
						&.t {
							left: 36.5%;
							top: 70.5%;
						}
						&.o {
							left: 49%;
							top: 59%;
						}
						&.p3 {
							left: 90%;
							top: 38%;
						}
					}
				}
			}
		}
		@include mobile {
			.map {
				.info-list {
					.info {
						&-content button {
							pointer-events: none;
						}
					}
				}
				.map-figure i.img {
					background-image: url(../img/overview_mobile.jpg);
				}
				.btn-modal div,
				.btn-modal button,
				.sub-title {
					display: none;
				}
			}
		}
	}
	// ============================== 객실소개  끝  ==============================

	// ============================== 펜션소식 시작 ==============================
	&.news {
		grid-template-columns: repeat(4, 1fr);
		> button {
			text-align: left;
			width: 100%;
			overflow: hidden;
			.news-figure {
				margin-bottom: 1.6rem;
			}
			h3 {
				@include text-ellipsis(2);
				height: 5.6rem;
				word-break: break-all;
			}
			.date {
				color: #666;
				text-align: right;
				margin: 0.2rem;
				position: relative;
				font-size: 1.4rem;
				&::before {
					content: '';
					display: block;
					width: calc(100% - 7rem);
					height: 0.1rem;
					border-top: 0.1rem dashed $default;
					position: absolute;
					left: 0;
					top: calc(50% - 0.05rem);
				}
			}
			.text {
				img {
					display: none;
				}
				@include text-ellipsis(5);
			}
			&:first-child h3 {
				position: relative;
				padding-right: 2.2rem;
				&::before {
					content: 'N';
					display: block;
					width: 2rem;
					line-height: 2rem;
					border-radius: 0.4rem;
					text-align: center;
					position: absolute;
					right: 0;
					top: 0.4rem;
					font-size: 1.4rem;
					background-color: #ff6259;
					font-weight: 700;
					font-family: 'ChosunLo', sans-serif;
					color: #fff;
				}
			}
		}
		@include tablet {
			grid-template-columns: repeat(3, 1fr);
		}
		@include mobile {
			button {
				h3 {
					height: auto;
				}
			}
		}
	}
	// ============================== 펜션소식  끝  ==============================

	// ============================== 예약하기 시작 ==============================
	&.reservation {
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			'caution info info'
			'fee fee fee'
			'penalty penalty penalty'
			'shortcut shortcut shortcut'
			'bookdate bookdate bookroom';
		// 'bookinfo bookinfo btnbook';
		.caution {
			grid-area: caution;
		}
		.info {
			grid-area: info;
		}
		.fee {
			grid-area: fee;
		}
		.penalty {
			grid-area: penalty;
		}
		.shortcut {
			grid-area: shortcut;
			.designed-border {
				display: block;
				text-align: center;
				.border-inner {
					padding: 4rem 2rem;
				}
			}
		}
		.book-date {
			grid-area: bookdate;
			.rdrMonths {
				margin-top: 3.6rem;
			}
		}
		.book-room {
			grid-area: bookroom;
			position: relative;
			padding-top: 6.6rem;
			&::before {
				content: '';
				display: block;
				width: 2.4rem;
				height: 0.2rem;
				position: absolute;
				left: -2.4rem;
				top: -0.2rem;
				background: $default;
			}
			.room-select {
				@include scrollbar(0.8rem, 0);
				overflow-y: auto;
				overflow-x: hidden;
				height: 40rem;
				display: flex;
				flex-direction: column;
				gap: 0.4rem;
				.price {
					margin-left: auto;
				}
				.btn-detail {
					@include icon(icon-search, 2.4rem);
				}
				.checkbox {
					input {
						&:checked + label {
							background-color: rgba($default, 0.7);
							* {
								color: #fff;
							}
							.btn-detail {
								@include icon(icon-search, 2.4rem, 'fff');
							}
						}
						& + label {
							border: 0.2rem solid $default;
							padding: 0.8rem 4.4rem 0.8rem 1.2rem;
							border-radius: 0.4rem;
							display: flex;
							align-items: center;
							gap: 0.8rem;
							transition-duration: 0.5s;
							&::before {
								position: absolute;
								right: 1.2rem;
								top: calc(50% - 1.2rem);
							}
						}
					}
				}
			}
		}
		.book-info {
			grid-area: bookinfo;
			display: flex;
		}
		.btn-book {
			grid-area: btnbook;
			padding-top: 0;
			.border-inner {
				padding: 4rem;
				flex-direction: row;
				gap: 1.2rem;
				flex-wrap: wrap;
				font-size: 1.8rem;
				.range {
				}
			}
		}
		@include mobile {
			.book-room {
				padding-top: 1.2rem;
				&::before {
					content: unset;
				}
				.room-select {
					height: auto;
				}
			}
			.btn-info {
			}
		}
	}
	// ============================== 예약하기  끝  ==============================

	// ============================== 결제하기 시작 ==============================
	&.payment {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'selected inpInfo'
			'terms terms';
		.selected {
			grid-area: selected;
		}
		.inp-info {
			grid-area: inpInfo;
		}
		.terms {
			grid-area: terms;
			.check-all {
				&.active {
					color: #f2f219;
				}
			}
			.accord-wrap {
				.accord-panel {
					.accord-title {
						background: none !important;
						&::before,
						&::after {
							background: $default;
						}
						.checkbox {
							label {
								display: block;
								position: relative;
								padding-left: 3.2rem;
								&::before {
									position: absolute;
									left: 0;
								}
							}
						}
						button {
							width: 4.6rem;
							height: 4.6rem;
							position: absolute;
							right: 0;
							top: 0;
						}
					}
				}
			}
		}
	}
	// ============================== 결제하기  끝  ==============================

	// ============================== 주변소개 시작 ==============================
	&.around {
		grid-template-columns: 1fr 3fr;
		.around-tabs {
			position: sticky;
			top: 8.4rem;
			max-height: calc(100vh - 11.6rem);
			min-width: 25.5rem;
			@include scrollbar(0.8rem, 0);
			overflow-y: auto;
			overflow-x: hidden;
			.accord-panel {
				.accord-content {
					position: relative;
					button {
						width: 100%;
						transition-duration: 0.5s;
						padding: 1.2rem;
						border-bottom: 0.1rem solid $default;
						&.active {
							background: rgba($default, 0.6);
							color: $primary;
						}
						@include hoverable {
							&:hover {
								background: rgba($default, 0.1);
							}
						}
					}
					&::after {
						content: '';
						position: absolute;
						right: 0;
						bottom: 0;
						display: block;
						width: 100%;
						height: 0.1rem;
						background-color: $default;
					}
				}
			}
		}
		.around-content {
			.title {
				.h4 {
					margin-left: 0.8rem;
					color: rgba($default, 0.5);
				}
			}
			.table-wrap {
				a {
					text-decoration: underline;
					color: rgba($default, 0.7);
				}
			}
			img:not([src='']) {
				aspect-ratio: 16/9;
				object-fit: contain;
				background: rgba($default, 0.6);
			}
			img[src=''] {
				display: none;
			}
			> *:not(.title, .title + *) {
				margin-top: 2.4rem;
			}
			.text {
				text-indent: 0;
			}
			.source {
				color: #666;
				text-align: right;
				margin-top: 1.2rem;
			}
		}
		@include mobile {
			.around-tabs {
				position: relative;
				top: unset;
				max-height: unset;
				.accord-wrap .accord-panel .accord-content {
					display: flex;
					flex-wrap: wrap;
					button {
						width: 50%;
						@include text-ellipsis(1);
					}
				}
			}
		}
	}
	// ============================== 주변소개  끝  ==============================

	// ============================== 자주하는질문 시작 ==============================
	&.faq {
		grid-template-columns: 1fr 3fr;
		.sort {
			.tab-title {
				display: flex;
				flex-direction: column;
				align-items: center;
				button {
					width: 100%;
					transition-duration: 0.5s;
					font-size: 2rem;
					height: 5rem;
					border: solid $default;
					border-width: 0.1rem 0.2rem;
					padding: 0 0.8rem;
					&:first-child {
						border-top-width: 0.2rem;
					}
					&:last-child {
						border-bottom-width: 0.2rem;
					}
					&.active {
						color: $primary;
						background: rgba($default, 0.8);
					}
					@include hoverable {
						&:not(.active):hover {
							background: rgba($default, 0.2);
						}
					}
					h3 {
						color: inherit;
						font-size: inherit;
					}
				}
			}
		}
		.faq-list {
			.title {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				text-transform: capitalize;
				.btns {
					gap: 0.8rem;
					button {
						font-family: 'ChosunSm', serif;
						color: #666;
						&:last-child {
							margin-left: 0.8rem;
						}
					}
				}
			}
			.accord-wrap {
				.accord-panel {
					.accord-title {
						i {
							position: absolute;
							left: 1.2rem;
							top: 0.75rem;
							& + p {
								padding-left: 2.8rem;
							}
						}
					}
				}
				.accord-content {
					> * {
						padding: 1.6rem 2.4rem;
						.overview {
							display: block;
							margin-bottom: 0.6rem;
							aspect-ratio: 16/9;
							background: url(../img/overview_mobile.jpg) 0 0 / 100% no-repeat;
						}
						.list-style li a {
							color: rgba($default, 0.7);
						}
					}
				}
			}
		}
		@include mobile {
			.sort {
				.tab-title {
					flex-direction: row;
					button {
						flex: 1;
						border-width: 0.2rem 0.1rem;
						&:first-child {
							border-left-width: 0.2rem;
						}
						&:last-child {
							border-right-width: 0.2rem;
						}
					}
				}
			}
		}
	}
	// ============================== 자주하는질문  끝  ==============================
}
