.rdrCalendarWrapper * {
	font-family: 'ChosunSm', serif;
	font-size: inherit;
	line-height: inherit;
}

.rdrCalendarWrapper {
	position: relative;
	.rdrDateDisplayWrapper {
		position: relative;
		.rdrDateDisplay {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2.8rem;
			font-size: 2.4rem;
			line-height: 120%;
			&::before {
				content: '~';
				display: block;
				width: 2.8rem;
				line-height: 2.8rem;
				position: absolute;
				left: calc(50% - 1.4rem);
				top: 0;
				text-align: center;
			}
			.rdrDateDisplayItem {
				position: relative;
				width: 16rem;
				input {
					text-align: center;
					cursor: pointer;
					width: 100%;
				}
				&::before {
					content: '';
					display: block;
					width: calc(100% - 1.2rem);
					height: 0.2rem;
					position: absolute;
					left: 0.6rem;
					bottom: -0.4rem;
					transition-duration: 0.3s;
				}
				&.rdrDateDisplayItemActive {
					&::before {
						background: #000;
					}
				}
			}
		}
	}

	.rdrMonthAndYearWrapper {
		.rdrMonthAndYearPickers {
			display: none;
		}
		.rdrNextPrevButton {
			position: absolute;
			top: 0;
			width: 2.4rem;
			height: 2.4rem;
			position: absolute;
			top: 0;
			border-radius: 0.4rem;
			transition-duration: 0.5s;
			z-index: 1;
			&:hover {
				background: #000;
				&.rdrPprevButton i {
					border-right-color: #fff;
				}
				&.rdrNextButton i {
					border-left-color: #fff;
				}
			}
			i {
				display: block;
				width: 0;
				height: 0;
				text-align: center;
				border: 0.6rem solid transparent;
				transition-duration: 0.5s;
			}
			&.rdrPprevButton {
				left: 0;
				i {
					border-right: 0.8rem solid #000;
				}
			}
			&.rdrNextButton {
				right: 0;
				i {
					border-left: 0.8rem solid #000;
					transform: translateX(0.8rem);
				}
			}
		}
	}

	.rdrMonths {
		display: flex;
		gap: 1.2rem;
		&.rdrMonthsVertical {
			flex-direction: column;
		}
		&.rdrMonthsHorizontal > div > div > div {
			display: flex;
			flex-direction: row;
		}
		.rdrMonth {
			width: 50%;
			min-height: 30rem;
			.rdrMonthName {
				font-size: 1.8rem;
				text-align: center;
			}
		}
		.rdrWeekDays {
			display: flex;
			.rdrWeekDay {
				width: calc(100% / 7);
				text-align: center;
				line-height: 4rem;
				font-family: 'ChosunKm', serif;
				border-bottom: 0.1rem solid #000;
				&:nth-child(1) {
					color: #f24430;
				}
				&:nth-child(7) {
					color: #4881ff;
				}
			}
		}
		.rdrDays {
			display: flex;
			flex-wrap: wrap;
			.rdrDay {
				position: relative;
				width: calc(100% / 7);
				height: 4rem;
				text-align: center;
				&:nth-child(7n + 1) span {
					color: #f24430;
				}
				&:nth-child(7n) span {
					color: #4881ff;
				}
				&.rdrDayPassive {
					pointer-events: none;
					background-color: #00000022;
					*:not(.rdrDayNumber, .rdrDayNumber span) {
						display: none;
					}
					.rdrDayNumber span {
						color: #00000088;
					}
				}
				&.rdrDayDisabled {
					cursor: not-allowed;
					background-color: #00000022;
					.rdrDayNumber span {
						color: #00000088;
					}
				}
				&.rdrDayToday {
					.rdrDayNumber span:after {
						content: '';
						position: absolute;
						left: calc(50% - 0.9rem);
						bottom: 0.6rem;
						display: block;
						width: 1.8rem;
						height: 0.2rem;
						border-radius: 0.1rem;
						background: #000000;
					}
					&:not(.rdrDayPassive) {
						.rdrInRange,
						.rdrStartEdge,
						.rdrEndEdge,
						.rdrSelected {
							& ~ .rdrDayNumber span:after {
								background: #fff;
							}
						}
					}
				}
				&:not(.rdrDayPassive) {
					.rdrInRange,
					.rdrStartEdge,
					.rdrEndEdge,
					.rdrSelected {
						& ~ .rdrDayNumber span {
							color: #fff;
						}
					}
				}
				.rdrSelected,
				.rdrInRange,
				.rdrStartEdge,
				.rdrEndEdge {
					background: #000;
					position: absolute;
					top: 0.1rem;
					left: 0;
					right: 0;
					bottom: 0.1rem;
					&.rdrStartEdge {
						border-top-left-radius: 99rem;
						border-bottom-left-radius: 99rem;
						border-radius: 99rem 0 0 99rem;
						left: 0.1rem;
					}
					&.rdrEndEdge {
						border-top-right-radius: 99rem;
						border-bottom-right-radius: 99rem;
						right: 0.1rem;
					}
					&.rdrSelected {
						border-radius: 99rem;
						left: 0.1rem;
						right: 0.1rem;
					}
				}
				&.rdrDayStartOfMonth {
					.rdrSelected,
					.rdrInRange,
					.rdrStartEdge,
					.rdrEndEdge {
						border-top-left-radius: 99rem;
						border-bottom-left-radius: 99rem;
						left: 0.1rem;
					}
				}
				&.rdrDayEndOfMonth {
					.rdrSelected,
					.rdrInRange,
					.rdrStartEdge,
					.rdrEndEdge {
						border-top-right-radius: 99rem;
						border-bottom-right-radius: 99rem;
						right: 0.1rem;
					}
				}
				.rdrDayNumber {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
	border-top-left-radius: 99rem;
	border-bottom-left-radius: 99rem;
	border-left-width: 1px;
	left: 0px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
	border-top-right-radius: 99rem;
	border-bottom-right-radius: 99rem;
	border-right-width: 1px;
	right: 0px;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
	background: rgba(255, 255, 255, 0.09);
	position: absolute;
	top: 0.1rem;
	left: 0px;
	right: 0px;
	bottom: 0.1rem;
	pointer-events: none;
	border: 0px solid #000;
	z-index: 1;
}

.rdrDayStartPreview {
	border-top-width: 1px;
	border-left-width: 1px;
	border-bottom-width: 1px;
	border-top-left-radius: 99rem;
	border-bottom-left-radius: 99rem;
	left: 0px;
}

.rdrDayInPreview {
	border-top-width: 1px;
	border-bottom-width: 1px;
}

.rdrDayEndPreview {
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-top-right-radius: 99rem;
	border-bottom-right-radius: 99rem;
	right: 0.1rem;
	right: 0px;
}

.rdrDefinedRangesWrapper {
	width: 226px;
	border-right: solid 1px #000;
	background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
	color: #000;
}

.rdrStaticRange {
	border: 0;
	cursor: pointer;
	display: block;
	outline: 0;
	border-bottom: 1px solid #000;
	background: #fff;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
	background: #000;
}

.rdrStaticRangeLabel {
	display: block;
	outline: 0;
	text-align: left;
}

.rdrInputRange {
	align-items: center;
}

.rdrInputRangeInput {
	width: 30px;
	height: 30px;
	border-radius: 4px;
	text-align: center;
	border: solid 1px #fff;
	color: #fff;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
	border-color: #fff;
	outline: 0;
	color: #000;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
	content: '';
	border: 1px solid #000;
	border-radius: 99rem;
	position: absolute;
	top: -0.1rem;
	bottom: -0.1rem;
	left: 0px;
	right: 0px;
	background: transparent;
}

// .rdrInfiniteMonths {
// 	overflow: auto;
// }
// .rdrDateInput .rdrWarning {
// 	position: absolute;
// 	top: 0;
// 	right: 0.25em;
// 	color: #000;
// }
// .rdrSelected,
// .rdrInRange,
// .rdrStartEdge,
// .rdrEndEdge {
// 	pointer-events: none;
// }
// .rdrDayStartPreview,
// .rdrDayInPreview,
// .rdrDayEndPreview {
// 	pointer-events: none;
// }
// .rdrDateRangePickerWrapper {
// 	display: inline-flex;
// 	user-select: none;
// }
// .rdrStaticRanges {
// 	display: flex;
// 	flex-direction: column;
// }
// .rdrInputRange {
// 	display: flex;
// }
// .rdrMonthAndYearPickers select {
// 	appearance: none;
// 	border: 0;
// 	background: transparent;
// 	border-radius: 4px;
// 	outline: 0;
// 	color: #000;
// 	background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
// 		no-repeat;
// 	background-position: right 8px center;
// 	cursor: pointer;
// 	text-align: center;
// }
// .rdrMonthAndYearPickers select:hover {
// 	background-color: rgba(0, 0, 0, 0.07);
// }
// .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
// 	display: none;
// }
