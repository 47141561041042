$default: #000;
$primary: #fff;
// ============================== 스와이퍼 시작 ==============================
.swiper,
.swiper-container {
	overflow: hidden;
	height: 100%;
	transform: translate3d(0, 0, 0);
}
.swiper-vertical > .swiper-wrapper {
	flex-direction: column;
}
.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	transition-timing-function: initial;
	box-sizing: content-box;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0, 0, 0);
}
.swiper-horizontal {
	touch-action: pan-y;
}
.swiper-vertical {
	touch-action: pan-x;
}
.swiper-slide,
swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
	display: block;
}
.swiper-slide-invisible-blank {
	visibility: hidden;
}
/* Auto Height */
.swiper-autoheight,
.swiper-autoheight .swiper-slide {
	height: auto;
}
.swiper-autoheight .swiper-wrapper {
	align-items: flex-start;
	transition-property: transform, height;
}
.swiper-backface-hidden .swiper-slide {
	transform: translateZ(0);
	backface-visibility: hidden;
}
/* 3D Effects */
.swiper-3d.swiper-css-mode .swiper-wrapper {
	perspective: 120rem;
}
.swiper-3d .swiper-wrapper {
	transform-style: preserve-3d;
}
.swiper-3d {
	perspective: 120rem;
	.swiper-slide,
	.swiper-slide-shadow,
	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right,
	.swiper-slide-shadow-top,
	.swiper-slide-shadow-bottom,
	.swiper-cube-shadow {
		transform-style: preserve-3d;
	}
	.swiper-slide-shadow,
	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right,
	.swiper-slide-shadow-top,
	.swiper-slide-shadow-bottom {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
	}
	.swiper-slide-shadow {
		background: rgba($default, 0.15);
	}
	.swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba($default, 0.5), rgba($default, 0));
	}
	.swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba($default, 0.5), rgba($default, 0));
	}
	.swiper-slide-shadow-top {
		background-image: linear-gradient(to top, rgba($default, 0.5), rgba($default, 0));
	}
	.swiper-slide-shadow-bottom {
		background-image: linear-gradient(to bottom, rgba($default, 0.5), rgba($default, 0));
	}
}
/* CSS Mode */
.swiper-css-mode {
	> .swiper-wrapper {
		overflow: auto;
		scrollbar-width: none; /* For Firefox */
		-ms-overflow-style: none; /* For Internet Explorer and Edge */
		&::-webkit-scrollbar {
			display: none;
		}
	}
	> .swiper-wrapper > .swiper-slide {
		scroll-snap-align: start start;
	}
}
.swiper-horizontal.swiper-css-mode {
	> .swiper-wrapper {
		scroll-snap-type: x mandatory;
	}
}
.swiper-vertical.swiper-css-mode {
	> .swiper-wrapper {
		scroll-snap-type: y mandatory;
	}
}
.swiper-centered {
	> .swiper-wrapper::before {
		content: '';
		flex-shrink: 0;
		order: 9999;
	}
	> .swiper-wrapper > .swiper-slide {
		scroll-snap-align: center center;
		scroll-snap-stop: always;
	}
}
.swiper-centered.swiper-horizontal {
	> .swiper-wrapper > .swiper-slide:first-child {
		margin-inline-start: auto;
	}
	> .swiper-wrapper::before {
		height: 100%;
		min-height: 0.1rem;
		width: auto;
	}
}
.swiper-centered.swiper-vertical {
	> .swiper-wrapper > .swiper-slide:first-child {
		margin-block-start: auto;
	}
	> .swiper-wrapper::before {
		width: 100%;
		min-width: 0.1rem;
		height: auto;
	}
}

.swiper-lazy-preloader {
	width: 4.2rem;
	height: 4.2rem;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -2.1rem;
	margin-top: -2.1rem;
	z-index: 10;
	transform-origin: 50%;
	box-sizing: border-box;
	border: 0.4rem solid $default;
	border-radius: 50%;
	border-top-color: transparent;
}
.swiper:not(.swiper-watch-progress),
swiper-container:not(.swiper-watch-progress),
.swiper-watch-progress .swiper-slide-visible {
	.swiper-lazy-preloader {
		animation: swiper-preloader-spin 1s infinite linear;
	}
}
.swiper-lazy-preloader-white {
	--swiper-preloader-color: $primary;
}
.swiper-lazy-preloader-black {
	--swiper-preloader-color: $default;
}
@keyframes swiper-preloader-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

// ============================== 스와이퍼 끝 ==============================

// ============================== 버튼 시작 ==============================
.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	width: 2.8rem;
	height: 2.8rem;
	z-index: 1;
	bottom: 3.6rem;
	cursor: pointer;
	background: icon(icon-swiper, '333', 'fff');
	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
	&.swiper-button-prev {
		left: 1%;
	}
	&.swiper-button-next {
		right: 1%;
		transform: rotate(180deg);
	}
	&.swiper-button-hidden {
		opacity: 0;
		cursor: auto;
		pointer-events: none;
	}
	.swiper-navigation-disabled & {
		display: none !important;
	}
	@include mobile {
		width: 2rem;
		height: 2rem;
	}
}
.swiper-button-lock {
	display: none;
}
// ============================== 버튼  끝  ==============================

// ============================== 스크롤바 시작 ==============================
.swiper-scrollbar {
	border-radius: 1rem;
	position: relative;
	-ms-touch-action: none;
	background: rgba($primary, 0.4);
	.swiper-scrollbar-disabled > &,
	&.swiper-scrollbar-disabled {
		display: none !important;
	}
	.swiper-horizontal > &,
	&.swiper-scrollbar-horizontal {
		position: absolute;
		left: 1%;
		bottom: 2.8rem;
		z-index: 50;
		height: 0.4rem;
		width: 98%;
	}
	.swiper-vertical > &,
	&.swiper-scrollbar-vertical {
		position: absolute;
		right: 0.4rem;
		top: 1%;
		z-index: 50;
		width: 0.4rem;
		height: 98%;
	}
}
.swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: rgba($default, 0.8);
	border-radius: 1rem;
	left: 0;
	top: 0;
}
.swiper-scrollbar-cursor-drag {
	cursor: move;
}
.swiper-scrollbar-lock {
	display: none;
}

.swiper-fade {
	&.swiper-free-mode {
		.swiper-slide {
			transition-timing-function: ease-out;
		}
	}
	.swiper-slide {
		pointer-events: none;
		transition-property: opacity;
		.swiper-slide {
			pointer-events: none;
		}
	}
	.swiper-slide-active {
		&,
		& .swiper-slide-active {
			pointer-events: auto;
		}
	}
}
// ============================== 스크롤바  끝  ==============================

// ============================== 썸네일 시작 ==============================
.swiper-thumbs {
	.swiper-slide {
		cursor: pointer;
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: $primary;
			transition-duration: 0.5s;
			opacity: 0.6;
		}
	}
	.swiper-slide-thumb-active {
		&::after {
			opacity: 0.1;
		}
	}
}
// ============================== 썸네일  끝  ==============================
